import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/modules/users/UsersList.vue"),
        },
        {
          path: "/trainings",
          name: "trainings",
          component: () => import("@/view/modules/trainings/TrainingsList.vue"),
        },
        {
          path: "/trainings-form",
          name: "training-form",
          component: () => import("@/view/modules/trainings/TrainingsForm.vue"),
        },
        {
          path: "/trainings-detail",
          name: "traning-detail",
          component: () =>
            import("@/view/modules/trainings/TrainingsDetail.vue"),
        },
        {
          path: "/courses",
          name: "courses",
          component: () => import("@/view/modules/courses/CoursesList.vue"),
        },
        {
          path: "/courses-form",
          name: "courses-form",
          component: () => import("@/view/modules/courses/CoursesForm.vue"),
        },
        {
          path: "/courses-detail",
          name: "courses-detail",
          component: () => import("@/view/modules/courses/CoursesDetail.vue"),
        },
        {
          path: "/orders",
          name: "orders",
          component: () => import("@/view/modules/orders/OrdersList.vue"),
        },
        {
          path: "/new-order",
          name: "new-order",
          component: () => import("@/view/modules/orders/NewOrder.vue"),
        },
        {
          path: "/order-detail",
          name: "order-detail",
          component: () => import("@/view/modules/orders/OrderDetail.vue"),
        },
        {
          path: "/order-modify",
          name: "order-modify",
          component: () => import("@/view/modules/orders/ModifyOrder.vue"),
        },
        {
          path: "/students",
          name: "students",
          component: () => import("@/view/modules/students/StudentList.vue"),
        },
        {
          path: "/student-detail",
          name: "student-detail",
          component: () => import("@/view/modules/students/StudentDetail.vue"),
        },
        {
          path: "/student-modify",
          name: "student-modify",
          component: () => import("@/view/modules/students/StudentModify.vue"),
        },
        {
          path: "/report_student_state",
          name: "report_student_state",
          component: () => import("@/view/modules/students/ReportStudentState.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
