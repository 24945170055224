import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "@/core/services/api.service";
import { VERIFY_AUTH } from "@/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/store/config.module";
import ToastificationContent from "@/core/components/toastification/ToastificationContent.vue";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

// API service init
ApiService.init();

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import moment from "moment";

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
})

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.prototype.$notify = function (title, text, icon, variant) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: title || 'Alerta',
      text: text || 'An error has occurred',
      icon: icon || 'AlertTriangleIcon',
      variant: variant || 'warning',
    },
  })
}

Vue.prototype.$handleSuccessResponse = function (response, message = null) {
  message = message || 'Action successful.'
  const { result = message } = response.data
  if (response && result) {
    this.$notify('Exitoso!', result, 'CheckCircleIcon', 'success')
  }
}

Vue.prototype.$handleErrorResponse = function (error, retry = true) {
  error = this.parseError(error)
  if (error) {
    if (this.isRetrievable(error) && retry) {
      this.$notify('An error has occurred', error.message, 'XCircleIcon', 'danger')
      throw error
    } else {
      this.$notify('An error has occurred', error.message, 'XCircleIcon', 'danger')
      if (error.errors) {
        const keys = Object.keys(error.errors)
        keys.forEach(k => {
          (error.errors[k] || []).forEach(element => {
            if (element) {
              this.$notify('An error has occurred', element, 'AlertTriangleIco', 'warning')
            }
          })
        })
      }
    }
  }
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
