import ApiService from "@core/services/api.service";

//import axios from "axios";

// action types
export const ORDERS_GET_ORDER = "getOrder";
export const ORDERS_GET_ORDERS_LIST = "getOrdersList";
export const ORDERS_EXPORT_ORDERS_LIST = "exportOrdersList";
export const ORDERS_GET_TRAININGS_LIST = "getOrderTrainingList";
export const ORDERS_GET_ENTERPRISE_LIST = "getOrderEnterpriseList";
export const ORDERS_GET_ENTERPRISE_LIST_EXT = "getOrderEnterpriseListExt";

// mutation types
export const ORDERS_SET_ORDERS_LIST = "setOrdersList";
export const ORDERS_SET_ORDERS_LIST_TOTAL = "setOrdersListTotal";
export const ORDERS_SET_SELECTED_ORDER_ID = "setSelectedOrderId";
export const ORDERS_SET_SELECTED_ORDER = "setSelectedOrder";
export const ORDERS_SET_NEW_ORDER_DATA = "setNewOrderData";
export const ORDERS_SET_NEW_ORDER_STUDENTS = "setNewOrderDataStudents";
export const ORDERS_VALIDATE_FILE = "validateFile";
export const ORDERS_STORE_ORDER = "storeOrder";
export const ORDERS_UPDATE_ORDER = "updateOrder";
export const ORDERS_DELETE_ORDER = "deleteOrder";
export const ORDERS_CHECK_DATA = "ordersCheckData";
export const ORDERS_SEND_WELCOME_CARD = "ordersSendWelcomeCard";
export const ORDERS_SET_TRAINING_LIST = "setOrdersTrainingList";
export const ORDERS_SET_TRAINING_LIST_TOTAL = "setOrdersTrainingListTotal";
export const ORDERS_SET_ENTERPRISE_LIST = "setOrdersEnterpriseList";
export const ORDERS_SET_ENTERPRISE_LIST_TOTAL = "setOrdersEnterpriseListTotal";
export const ORDERS_SET_ENTERPRISE_LIST_EXT = "setOrdersEnterpriseListExt";
export const ORDERS_SET_ENTERPRISE_LIST_EXT_TOTAL = "setOrdersEnterpriseListExtTotal";
export const ORDERS_SET_VALUE_FOR_STUDENT_IN_LIST = "setOrdersValueForStudentInList";

const state = {
  ordersListTotal: 8,
  selectedOrderId: 0,
  selectedOrder: {},
  ordersList: [],
  orderTrainingList: [],
  orderTrainingListTotal: 0,
  orderEnterpriseList: [],
  orderEnterpriseListTotal: 0,
  orderEnterpriseListExt: [],
  orderEnterpriseListExtTotal: 0,
  newOrderData: {
    enterprise_id: null,
    training_id: null,
    start_date: null,
    end_date: null,
    minimal_choice: 1,
    branch: null,
    area: null,
    type: 1,
    state: 1,
    welcome_message: null,
    bye_message: null,
    original_file: null,
    send_welcome_card: 1,
    file: [],
    students: [],
  },
};

const getters = {};

const actions = {
  [ORDERS_GET_ORDERS_LIST](context, filters) {
    let urlSP = new URLSearchParams(filters.filters)
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/order?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage +
          "&search=" + filters.search + "&" + urlSP.toString().replace(/undefined/g, '')
      )
          .then(({data}) => {
            context.commit(ORDERS_SET_ORDERS_LIST, data.data);
            context.commit(ORDERS_SET_ORDERS_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [ORDERS_EXPORT_ORDERS_LIST](context, filters) {
    let urlSP = new URLSearchParams(filters.filters)
    return new Promise((resolve) => {
      ApiService.postGetFile(
          "edu-training/order/excel-export?search=" + filters.search + "&" +
          urlSP.toString().replace(/undefined/g, ''), {}, "order.xlsx"
      )
          .then(({data}) => {
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [ORDERS_GET_ORDER](context, filters) {
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/order/" + filters.id
      )
          .then(({data}) => {
            context.commit(ORDERS_SET_SELECTED_ORDER, data);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [ORDERS_VALIDATE_FILE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.postFormdata("edu-training/order/student_template_excel", data)
        .then( (response)  => {
          resolve(response)
        })
        .catch(( errors ) => {
          reject({ success: false, errors: errors });
        });
    });
  },
  [ORDERS_STORE_ORDER](context, data) {
    return new Promise((resolve) => {
      ApiService.post("edu-training/order", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response);
          resolve({ success: false });
        });
    });
  },
  [ORDERS_UPDATE_ORDER](context, data) {
    return new Promise((resolve) => {
      ApiService.update("edu-training/order", data.id, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response);
          resolve({ success: false });
        });
    });
  },
  [ORDERS_DELETE_ORDER](context, data) {
    return new Promise((resolve) => {
      ApiService.delete("edu-training/order/" + data.id, data)
          .then(({ response }) => {
            resolve(response);
          })
          .catch(({ error }) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [ORDERS_CHECK_DATA](context, data) {
    return new Promise((resolve) => {
      ApiService.post("edu-training/order/order_check_data", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response);
          resolve({ success: false });
        });
    });
  },
  [ORDERS_SEND_WELCOME_CARD](context, data) {
    return new Promise((resolve) => {
      ApiService.post("edu-training/student/send_welcome_card", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response);
          resolve({ success: false });
        });
    });
  },
  [ORDERS_GET_TRAININGS_LIST](context, filters) {
    let scope = this
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/training?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage
      )
          .then(({data}) => {
            context.commit(ORDERS_SET_TRAINING_LIST, data.data);
            context.commit(ORDERS_SET_TRAINING_LIST_TOTAL, data.total);

            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [ORDERS_GET_ENTERPRISE_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/enterprise?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage
      )
          .then(({data}) => {
            context.commit(ORDERS_SET_ENTERPRISE_LIST, data.data);
            context.commit(ORDERS_SET_ENTERPRISE_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [ORDERS_GET_ENTERPRISE_LIST_EXT](context, filters) {
    return new Promise((resolve) => {
      ApiService.post(
          "empresa/listado?page=" + filters.page +
          "&rowsPerPage=" + filters.itemsPerPage
      )
          .then(({data}) => {
            let enterpriseListExt = data.data
            context.commit(ORDERS_SET_ENTERPRISE_LIST_EXT, enterpriseListExt.data);
            context.commit(ORDERS_SET_ENTERPRISE_LIST_EXT_TOTAL, enterpriseListExt.data.length);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
};

const mutations = {
  [ORDERS_SET_ORDERS_LIST](state, value) {
    state.ordersList = value;
  },
  [ORDERS_SET_ORDERS_LIST_TOTAL](state, value) {
    state.ordersListTotal = value;
  },
  [ORDERS_SET_SELECTED_ORDER_ID](state, value) {
    state.selectedOrderId = value;
  },
  [ORDERS_SET_SELECTED_ORDER](state, value) {
    state.selectedOrder = value;
  },
  [ORDERS_SET_NEW_ORDER_DATA](state, value) {
    state.newOrderData = value;
  },
  [ORDERS_SET_NEW_ORDER_STUDENTS](state, value) {
    state.newOrderData.students = value;
  },
  [ORDERS_SET_TRAINING_LIST](state, value) {
    state.orderTrainingList = value;
  },
  [ORDERS_SET_TRAINING_LIST_TOTAL](state, value) {
    state.orderTrainingListTotal = value;
  },
  [ORDERS_SET_ENTERPRISE_LIST](state, value) {
    state.orderEnterpriseList = value;
  },
  [ORDERS_SET_ENTERPRISE_LIST_TOTAL](state, value) {
    state.orderEnterpriseListTotal= value;
  },
  [ORDERS_SET_ENTERPRISE_LIST_EXT](state, value) {
    state.orderEnterpriseListExt = value;
  },
  [ORDERS_SET_ENTERPRISE_LIST_EXT_TOTAL](state, value) {
    state.orderEnterpriseListExtTotal = value;
  },
  [ORDERS_SET_VALUE_FOR_STUDENT_IN_LIST](state, info) {
    state.newOrderData.students.find(s => s.rut == info.rut)[info.field] = info.value
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
