// action types
import ApiService from "@core/services/api.service";

export const GET_TRAININGS_LIST = "getTrainingsList";
export const TRAINING_GET_COURSES_LIST = "getTrainingCoursesList";
export const STORE_TRAINING = "storeTraining";
export const UPDATE_TRAINING = "updateTraining";
export const DELETE_TRAINING = "deleteTraining";

// mutation types
export const SET_TRAININGS_LIST = "setTrainingsList";
export const SET_SELECTED_TRAINING = "setSelectedTraining";
export const SET_TRAININGS_LIST_TOTAL = "setTrainingsListTotal";
export const TRAINING_SET_COURSES_LIST = "setTrainingsCourseList";
export const TRAINING_SET_COURSES_LIST_TOTAL = "setTrainingsCourseListTotal";

const state = {
  trainingsListTotal: 2,
  selectedTraining: null,
  trainingsSimpleList: [
    { id: 1, name: "Microsoft office 2013 intermedio" },
    { id: 2, name: "Seguridad en el trabajo" },
  ],
  trainingsList: [],
  coursesListTotal: 0,
  coursesList: [],
};

const getters = {};

const actions = {
  [GET_TRAININGS_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/training?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage + "&search=" + filters.search
      )
          .then(({data}) => {
            context.commit(SET_TRAININGS_LIST, data.data);
            context.commit(SET_TRAININGS_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [STORE_TRAINING](context, data) {
    return new Promise((resolve) => {
      ApiService.post("edu-training/training", data)
          .then(({ response }) => {
            resolve(response);
          })
          .catch(({ error }) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [UPDATE_TRAINING](context, data) {
    return new Promise((resolve) => {
      ApiService.update("edu-training/training", data.id, data)
          .then(({ response }) => {
            resolve(response);
          })
          .catch(({ error }) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [TRAINING_GET_COURSES_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/course?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage + "&search=" + filters.search
      )
          .then(({ data }) => {
            context.commit(TRAINING_SET_COURSES_LIST, data.data);
            context.commit(TRAINING_SET_COURSES_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({ error }) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [DELETE_TRAINING](context, data) {
    return new Promise((resolve) => {
      ApiService.delete("edu-training/training/" + data.id, data)
          .then(({ response }) => {
            resolve(response);
          })
          .catch(({ error }) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
};

const mutations = {
  [SET_TRAININGS_LIST](state, value) {
    state.trainingsList = value;
  },
  [SET_TRAININGS_LIST_TOTAL](state, value) {
    state.trainingsListTotal = value;
  },
  [SET_SELECTED_TRAINING](state, value) {
    state.selectedTraining = value;
  },
  [TRAINING_SET_COURSES_LIST](state, value) {
    state.coursesList = value;
  },
  [TRAINING_SET_COURSES_LIST_TOTAL](state, value) {
    state.coursesListTotal = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
