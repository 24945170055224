import ApiService from "@core/services/api.service";

export const DASHBOARD_GET_STATS_LIST = "getDashboardStatsList";
//
export const DASHBOARD_SET_STATS_LIST = "setDashboardList";

const state = {
    DashboardStatsList: [],
};

const getters = {};

const actions = {
    [DASHBOARD_GET_STATS_LIST](context) {
        return new Promise((resolve) => {
            ApiService.get(
                "edu-training/dash/"
            )
                .then(({data}) => {
                    context.commit(DASHBOARD_SET_STATS_LIST, data.totals);
                    resolve(data);
                })
                .catch(({error}) => {
                    resolve({
                        error: error,
                        success: false
                    });
                });
        });
    },
};


const mutations = {
    [DASHBOARD_SET_STATS_LIST](state, value) {
        console.log(value, 'value')
        state.DashboardStatsList = value;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
