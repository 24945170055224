const ID_UINFO_KEY = "uinfo";

export const getUserInfo = () => {
  return JSON.parse(window.atob(window.localStorage.getItem(ID_UINFO_KEY)));
};

export const saveUserInfo = userInfo => {
  window.localStorage.setItem(ID_UINFO_KEY, window.btoa(JSON.stringify(userInfo)));
};

export const destroyUserInfo = () => {
  window.localStorage.removeItem(ID_UINFO_KEY);
};

export default { getUserInfo, saveUserInfo, destroyUserInfo };
