// action types
import ApiService from "@core/services/api.service";

export const COURSES_GET_COURSES_LIST = "getCoursesList";
export const COURSES_STORE_COURSE = "storeCourse";
export const COURSES_UPDATE_COURSE = "updateCourse";
export const COURSES_DELETE_COURSE = "deleteCourse";

// mutation types
export const COURSES_SET_COURSES_LIST = "setCoursesList";
export const COURSES_SET_COURSES_LIST_TOTAL = "setCoursesListTotal";
export const COURSES_SET_SELECTED_COURSE = "setSelectedCourse";

const state = {
  coursesListTotal: 8,
  selectedCourse: null,
  coursesList: [],
};

const getters = {};

const actions = {
  [COURSES_GET_COURSES_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/course?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage + "&search=" + filters.search
      )
          .then(({data}) => {
            context.commit(COURSES_SET_COURSES_LIST, data.data);
            context.commit(COURSES_SET_COURSES_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [COURSES_STORE_COURSE](context, data) {
    return new Promise((resolve) => {
      ApiService.post("edu-training/course", data)
        .then(({ response }) => {
          resolve(response);
        })
        .catch(({ error }) => {
          resolve({
            error: error,
            success: false
          });
        });
    });
  },
  [COURSES_UPDATE_COURSE](context, data) {
    return new Promise((resolve) => {
      ApiService.update("edu-training/course", data.id, data)
        .then(({ response }) => {
          resolve(response);
        })
        .catch(({ error }) => {
          resolve({
            error: error,
            success: false
          });
        });
    });
  },
  [COURSES_DELETE_COURSE](context, data) {
    return new Promise((resolve) => {
      ApiService.delete("edu-training/course/" + data.id, data)
        .then(({ response }) => {
          resolve(response);
        })
        .catch(({ error }) => {
          resolve({
            error: error,
            success: false
          });
        });
    });
  },
};

const mutations = {
  [COURSES_SET_COURSES_LIST](state, value) {
    state.coursesList = value;
  },
  [COURSES_SET_COURSES_LIST_TOTAL](state, value) {
    state.coursesListTotal = value;
  },
  [COURSES_SET_SELECTED_COURSE](state, value) {
    state.selectedCourse = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
