// action types
import ApiService from "@core/services/api.service";

export const STUDENTS_GET_STUDENTS_LIST = "getStudentsList";
export const STUDENTS_GET_REPORT_STUDENTS_LIST = "getReportStudentsList";
export const STUDENTS_EXPORT_STUDENTS_LIST = "exportStudentsList";
export const STUDENTS_EXPORT_REPORT_STUDENTS_LIST = "exportReportStudentsList";
export const STUDENTS_UPDATE_STUDENT = "updateStudent";
export const STUDENTS_DELETE_STUDENT = "deleteStudent";
export const STUDENTS_GET_TRAININGS_LIST = "getStudentTrainingList";
export const STUDENTS_GET_ENTERPRISE_LIST = "getStudentEnterpriseList";
export const STUDENTS_GET_TRAINING_STATUS_LIST = "getStudentTrainingStatusList";

// mutation types
export const STUDENTS_SET_STUDENTS_LIST = "setStudentsList";
export const STUDENTS_SET_STUDENTS_LIST_TOTAL = "setStudentsListTotal";
export const STUDENTS_SET_REPORT_STUDENTS_LIST = "reportStudentsList";
export const STUDENTS_SET_REPORT_STUDENTS_LIST_TOTAL = "reportStudentsListTotal";
export const STUDENTS_SET_SELECTED_STUDENT = "setSelectedStudent";
export const STUDENT_RESET = "restartSelectedStudent";
export const STUDENTS_SET_TRAINING_LIST = "setStudentTrainingList";
export const STUDENTS_SET_TRAINING_LIST_TOTAL = "setStudentTrainingListTotal";
export const STUDENTS_SET_ENTERPRISE_LIST = "setStudentEnterpriseList";
export const STUDENTS_SET_ENTERPRISE_LIST_TOTAL = "setStudentEnterpriseListTotal";
export const STUDENTS_SET_TRAINING_STATUS_LIST = "setStudentTrainingStatusList";
export const STUDENTS_SET_TRAINING_STATUS_LIST_TOTAL = "setStudentTrainingStatusListTotal";

const state = {
  studentsList: [],
  studentsListTotal: 8,
  reportStudentsList: [],
  reportStudentsListTotal: 8,
  selectedStudent: null,
  studentTrainingList: [],
  studentTrainingListTotal: 0,
  studentEnterpriseList: [],
  studentEnterpriseListTotal: 0,
  studentTrainingStatusList: [],
  studentTrainingStatusListTotal: 0,
};

const getters = {};

const actions = {
  [STUDENTS_GET_STUDENTS_LIST](context, filters) {
    return new Promise((resolve) => {
      let urlSP = new URLSearchParams(filters.filters)
      ApiService.get(
          "edu-training/student?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage +
          "&search=" + filters.search + "&" + urlSP.toString().replace(/undefined/g, '')
      )
          .then(({data}) => {
            context.commit(STUDENTS_SET_STUDENTS_LIST, data.data);
            context.commit(STUDENTS_SET_STUDENTS_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [STUDENTS_GET_REPORT_STUDENTS_LIST](context, filters) {
    return new Promise((resolve) => {
      let urlSP = new URLSearchParams(filters.filters)
      ApiService.get(
          "edu-training/student/report_student_selection?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage +
          "&search=" + filters.search + "&" + urlSP.toString().replace(/undefined/g, '')
      )
          .then(({data}) => {
            context.commit(STUDENTS_SET_REPORT_STUDENTS_LIST, data.data);
            context.commit(STUDENTS_SET_REPORT_STUDENTS_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [STUDENTS_EXPORT_STUDENTS_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.postGetFile(
          "edu-training/student/excel-export", filters.filters, "alumnos.xlsx"
      )
          .then(({data}) => {
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [STUDENTS_EXPORT_REPORT_STUDENTS_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.postGetFile(
          "edu-training/student/excel-report-selection-export", filters.filters, "report_selection.xlsx"
      )
          .then(({data}) => {
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [STUDENTS_UPDATE_STUDENT](context, data) {
    return new Promise((resolve) => {
      ApiService.update("edu-training/student", data.id, data)
        .then(({ response }) => {
          resolve(response);
        })
        .catch(({ error }) => {
          resolve({
            error: error,
            success: false
          });
        });
    });
  },
  [STUDENTS_DELETE_STUDENT](context, data) {
    return new Promise((resolve) => {
      ApiService.delete("edu-training/student/" + data.id, data)
        .then(({ response }) => {
          resolve(response);
        })
        .catch(({ error }) => {
          resolve({
            error: error,
            success: false
          });
        });
    });
  },
  [STUDENT_RESET](context, data) {
    return new Promise((resolve) => {
      ApiService.post("edu-training/student/reset", data)
        .then(({ response }) => {
          resolve(response);
        })
        .catch(({ error }) => {
          resolve({
            error: error,
            success: false
          });
        });
    });
  },
  [STUDENTS_GET_TRAININGS_LIST](context, filters) {
    let scope = this
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/training?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage
      )
          .then(({data}) => {
            context.commit(STUDENTS_SET_TRAINING_LIST, data.data);
            context.commit(STUDENTS_SET_TRAINING_LIST_TOTAL, data.total);

            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [STUDENTS_GET_ENTERPRISE_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/enterprise?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage
      )
          .then(({data}) => {
            context.commit(STUDENTS_SET_ENTERPRISE_LIST, data.data);
            context.commit(STUDENTS_SET_ENTERPRISE_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
  [STUDENTS_GET_TRAINING_STATUS_LIST](context, filters) {
    return new Promise((resolve) => {
      ApiService.get(
          "edu-training/student/status?page=" + filters.page + "&rowsPerPage=" + filters.itemsPerPage
      )
          .then(({data}) => {
            context.commit(STUDENTS_SET_TRAINING_STATUS_LIST, data.data);
            context.commit(STUDENTS_SET_TRAINING_STATUS_LIST_TOTAL, data.total);
            resolve(data);
          })
          .catch(({error}) => {
            resolve({
              error: error,
              success: false
            });
          });
    });
  },
};

const mutations = {
  [STUDENTS_SET_STUDENTS_LIST](state, value) {
    state.studentsList = value;
  },
  [STUDENTS_SET_STUDENTS_LIST_TOTAL](state, value) {
    state.studentsListTotal = value;
  },
  [STUDENTS_SET_REPORT_STUDENTS_LIST](state, value) {
    state.reportStudentsList = value;
  },
  [STUDENTS_SET_REPORT_STUDENTS_LIST_TOTAL](state, value) {
    state.reportStudentsListTotal = value;
  },
  [STUDENTS_SET_SELECTED_STUDENT](state, value) {
    state.selectedStudent = value;
  },
  [STUDENTS_SET_TRAINING_LIST](state, value) {
    state.studentTrainingList = value;
  },
  [STUDENTS_SET_TRAINING_LIST_TOTAL](state, value) {
    state.studentTrainingListTotal = value;
  },
  [STUDENTS_SET_ENTERPRISE_LIST](state, value) {
    state.studentEnterpriseList = value;
  },
  [STUDENTS_SET_ENTERPRISE_LIST_TOTAL](state, value) {
    state.studentEnterpriseListTotal = value;
  },
  [STUDENTS_SET_TRAINING_STATUS_LIST](state, value) {
    state.studentTrainingStatusList = value;
  },
  [STUDENTS_SET_TRAINING_STATUS_LIST_TOTAL](state, value) {
    state.studentTrainingStatusListTotal = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
