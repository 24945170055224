import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import courses from "./modules/courses.module";
import trainings from "./modules/trainings.module";
import orders from "./modules/orders.module";
import students from "./modules/students.module";
import dashboard from "./modules/dashboard.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    courses,
    trainings,
    orders,
    students,
    dashboard,
  },
});
